import React, { useState, useEffect } from 'react';

const MouseShadow = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (event) => {
    setPosition({
      x: event.clientX + window.scrollX,
      y: event.clientY + window.scrollY,
    });
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const shadowStyle = {
    position: 'absolute',
    top: position.y,
    left: position.x,
    width: '0', // velikost stínu
    height: '0',
    backgroundColor: 'transparent', // barva stínu
    borderRadius: '50%',
    pointerEvents: 'none',
    boxShadow: '0 0 40px 40px rgba(67, 217, 273, 0.1)', // vlastnosti stínu
    transform: 'translate(-50%, -50%)',
    zIndex: 5, // zajištění, že stín bude nad ostatními prvky
  };

  return <div style={shadowStyle}></div>;
};

export default MouseShadow;