import React from "react";
import Icon from '@mdi/react';
 
function LanguageCards(lang) {

    const language = lang.lang

    return (
        <div key={lang.name} style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>

                        <Icon path={language.icon} size={8} color={language.color}/>
                        <p>{language.name}</p>
 
        </div>
    )
}

export default LanguageCards