import React from "react";
import data from "../data/excellentcrates_users.json"


function Truhly() {


    return (
        <div className="truhly">
            <h1>Truhly</h1>
            <ul>
                {data.map(item => (
                    <>
                        <li key={item.name}>{item.name}, Vote: {item.keys.vote}, Ultra: {item.keys.ultra}</li>
                    </>
                ))}
            </ul>
        </div>
    )
}

export default Truhly