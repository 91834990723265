import React from "react";
import { Button } from "react-bootstrap";
import Icon from '@mdi/react';
import { mdiArrowLeft } from '@mdi/js';
import '../Styles/BackButton.css'

function BackButton() {

    const redirect = () => {
        window.location.href = '/';
    }

    return(
        <Button className="floating-button" variant="secondary" onClick={redirect}>
            <Icon path={mdiArrowLeft} size={8} />
        </Button>
    )
}

export default BackButton