import { mdiGit, mdiLanguageHtml5, mdiLanguageCss3, mdiLanguageJavascript, mdiReact, mdiBootstrap, mdiNodejs, mdiNpm, mdiDatabase } from '@mdi/js';

const languages = [
    {
        name: "Git",
        color: "#fff",
        icon: mdiGit
    },
    {
        name: "HTML",
        color: "#e34c26",
        icon: mdiLanguageHtml5
    },
    {
        name: "CSS",
        color: "#264de4",
        icon: mdiLanguageCss3
    },
    {
        name: "JavaScript",
        color: "#f7df1e",
        icon: mdiLanguageJavascript
    },
    {
        name: "React",
        color: "#61dbfb",
        icon: mdiReact
    },
    {
        name: "NodeJS",
        color: "#339933",
        icon: mdiNodejs
    },
    {
        name: "Bootstrap",
        color: "#6c19ff",
        icon: mdiBootstrap
    },
    {
        name: "npm",
        color: "#cc3534",
        icon: mdiNpm
    }
]

export default languages