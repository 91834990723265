import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainPage from './Components/MainPage';
import HexaConverter from './Components/HexaConverter';
import ShutTheBox from './Components/ShutTheBox';
import GuessTheNumber from './Components/GuessTheNumber';
import GuessTheWord from './Components/GuessTheWord'
import ShoppingList from './Components/ShoppingListApp/ShoppingList'
import MathGame from './Components/MathGame'
import Truhly from './Components/Truhly';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes> 
          <Route path="/" element={<MainPage/>}/>
          <Route path="/hexa" element={<HexaConverter/>}/>
          <Route path="/shutTheBox" element={<ShutTheBox/>}/>
          <Route path="/guessTheNumber" element={<GuessTheNumber/>}/>
          <Route path="/guessTheWord" element={<GuessTheWord/>}/>
          <Route path="/shoppingListApp" element={<ShoppingList/>}/>
          <Route path="/mathGame" element={<MathGame/>}/>
          <Route path="/truhly" element={<Truhly/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
