import React, {useEffect} from 'react';
import '../Styles/MainPage.css'
import { Link } from 'react-router-dom'
import photo from '../example.png'
import school from '../School.png'
import financio from '../Financio.png'
import toDoList from '../toDoList.png'
import mongoDB from '../MongoDB.png'
import express from '../express.png'
import mysql from '../mysql.png'
import sqlite from '../sqlite.png'
import LanguageCards from './Portfolio/LanguageCards';
import languages from '../data/languages';
import Icon from '@mdi/react';
import MouseShadow from './MouseShadow';
import { mdiXml, mdiOpenInNew, mdiGit, mdiLanguageHtml5, mdiLanguageCss3, mdiLanguageJavascript, mdiReact, mdiBootstrap, mdiNodejs, mdiNpm} from '@mdi/js';

function MainPage() {
  useEffect(() => {
    const applyBodyStyles = () => {
      if (window.matchMedia('(max-width: 600px)').matches) {
        document.body.style.margin = '0'
        document.body.style.borderRadius = '0'
        document.body.style.border = 'none'
      } else if (window.matchMedia('(max-width: 1000px)').matches) {
        document.body.style.margin = '10px'
        document.body.style.border = 'solid #305869 1px'
        document.body.style.borderRadius = '15px'
      } else {
        document.body.style.margin = '40px'
        document.body.style.border = 'solid #305869 1px'
        document.body.style.borderRadius = '15px'
      }

      document.body.style.backgroundColor = '#090f14'
    };
    applyBodyStyles()
    window.addEventListener('resize', applyBodyStyles)
    return () => {
      window.removeEventListener('resize', applyBodyStyles)
      document.body.style.backgroundColor = ''
      document.body.style.margin = ''
      document.body.style.border = ''
      document.body.style.borderRadius = ''
    }
  }, [])

  return (
    <div className="Portfolio">
    <div className="MainPage">
          <div className="First-section">
            <header className="App-header">
              <div className="header-text">
                <h1>Martin Sivak</h1>
                <h3 className="Turquise"> &gt; Software Development student</h3>
              </div>
              <div className="header-photo">
                <img src={photo} className="App-logo" alt="logo" />
              </div>
            </header>
          </div>
{/* Math game */}        
        <div className="Projects">
          <h1>My projects</h1>
          <div className='Project-card'>
            <div className="Desc">
              <div className="Card-text">
                <h3>NoteBuzz</h3>
                <p>React, CSS, Bootstrap, NodeJS, Express, MariaDB</p>
                <p>A comprehensive application designed for students, parents, teachers, and school administrators to manage and track academic progress. This user-friendly platform allows you to add and view grades for individual subjects, as well as manage and view schedules.</p>
                <p>Features:</p>
                <ul>
                  <li>Grade management for individual subjects</li>
                  <li>Accessible for students, parents, teachers, and administrators</li>
                  <li>Class schedule overview</li>
                </ul>
                <div className='Card-actions'>
                  <Link to="https://school.sivakm.com" target="_blank">
                    <button>View project</button>
                  </Link>
                  <Link to="https://github.com/lOlkOmOx/MathGame" target="_blank">
                    <button>Code</button>
                  </Link>
                </div>
              </div>
              <div className='IMG'>
                <img src={school} className="Project-photo" alt="mathGame" />
              </div>
            </div>
          </div>
{/* To-Do app*/}  
          <div className='Project-card'>
            <div className="Desc">
              <div className="Card-text">
                <h3>To-Do app</h3>
                <p>React, JavaScript, Bootstrap, NodeJS, Express, MongoDB</p>
                <p>FE & BE, My first project with server using Express. Simple to-do list app which enables you to categorise your tasks by schedule date.</p>
                <p>Features:</p>
                <ul>
                  <li>Categorize tasks by Today, This Week and Later</li>
                  <li>Indicate tasks difficulty and time required</li>
                  <li>Track progress with a progress bar</li>
                  <li>Easily add or edit tasks</li>
                </ul>
                <div className='Card-actions Flex-end'>
                  <Link to="https://todo.sivakm.com" target="_blank">
                    <button>View project</button>
                  </Link>
                  <Link to="https://github.com/lOlkOmOx/To-Do-App" target="_blank">
                    <button>Code</button>
                  </Link>
                </div>
              </div>
              <div className='IMG'>
                <img src={toDoList} className="Project-photo" alt="To-Do-list" />
              </div>
            </div>
          </div>
{/* Financio */} 
          <div className='Project-card'>
            <div className="Desc">
              <div className="Card-text">
                <h3>Financio</h3>
                <p>React, JavaScript, Bootstrap, NodeJS, Express, MongoDB</p>
                <p>Semester project from my second year at university, developed with my teammates. I was responsible for the frontend and API.</p>
                <p>Features:</p>
                <ul>
                  <li>Categorize your expenses</li>
                  <li>Schedule upcoming payments</li>
                  <li>Set savings goals</li>
                  <li>View cashflow graph</li>
                </ul>
                <div className='Card-actions Flex-end'>
                  <Link to="https://financio.sivakm.com" target="_blank">
                    <button>View project</button>
                  </Link>
                  <Link to="https://github.com/Miki-Herman/Financio" target="_blank">
                    <button>Code</button>
                  </Link>
                </div>
              </div>
              <div className='IMG'>
                <img src={financio} className="Project-photo" alt="financio" /> 
              </div>
            </div>
          </div>
          <h2>Fun projects</h2>
          <div className="Small-project-cards-container">
{/* Guess the number */}
            <div className="Small-project-card">
              <h4>Math Game</h4>
              <p>JavaScript, React, CSS</p>
              <p className="Justify-text">A React component featuring a simple math game that lets users set a timer, maximal number and choose arithmetic operations. Test your math skills now!</p>
              <div className="Small-project-actions">
                <Link className='Link' to="/mathGame">
                  <button className='Action-button'>
                    <Icon path={mdiOpenInNew} size={1} color="lightgray"/>
                  </button>
                </Link>
                <Link className='Link' to="https://github.com/lOlkOmOx/MathGame" target="_blank">
                  <button className='Action-button'>
                    <Icon path={mdiXml} size={1} color="lightgray"/>
                  </button>
                </Link>
              </div>
            </div>
{/* Guess the number */}
            <div className="Small-project-card">
              <h4>Guess the number</h4>
              <p>React, Bootstrap</p>
              <p className="Justify-text">Guess the number is a game where players try to guess a randomly selected number within a specified range, which can be customized.</p>
              <div className="Small-project-actions">
                <Link className='Link' to="/guessTheNumber">
                  <button className='Action-button'>
                    <Icon path={mdiOpenInNew} size={1} color="lightgray"/>
                  </button>
                </Link>
                <Link className='Link' to="https://github.com/lOlkOmOx/Guess-the-number" target="_blank">
                  <button className='Action-button'>
                    <Icon path={mdiXml} size={1} color="lightgray"/>
                  </button>
                </Link>
              </div>
            </div>
{/* Guess the word */}
            <div className="Small-project-card">
              <h4>Guess the word</h4>
              <p className="Justify-text">Guess the Word is a game inspired by Wordle, where players attempt to guess a hidden word within a given number of attempts.</p>
              <div className="Small-project-actions">
                <Link className='Link' to="/guessTheWord">
                  <button className='Action-button'>
                    <Icon path={mdiOpenInNew} size={1} color="lightgray"/>
                  </button>
                </Link>
                <Link className='Link' to="https://github.com/lOlkOmOx/Guess-the-word" target="_blank">
                  <button className='Action-button'>
                    <Icon path={mdiXml} size={1} color="lightgray"/>
                  </button>
                </Link>
              </div>
            </div>
{/* Shut the box */}
            <div className="Small-project-card">
              <h4>Shut the box!</h4>
              <p className="Justify-text">Shut the Box is a dice game where players roll dice to cover numbered tiles and aim to close all the tiles before running out of moves.</p>
              <div className="Small-project-actions">
                <Link className='Link' to="/shutTheBox">
                  <button className='Action-button'>
                    <Icon path={mdiOpenInNew} size={1} color="lightgray"/>
                  </button>
                </Link>
                <Link className='Link' to="https://github.com/lOlkOmOx/ShutTheBox" target="_blank">
                  <button className='Action-button'>
                    <Icon path={mdiXml} size={1} color="lightgray"/>
                  </button>
                </Link>
              </div>
            </div>
{/* Hexa converter */}
            <div className="Small-project-card">
              <h4>Hexa converter</h4>
              <p className="Justify-text">My first experience with JavaScript: a simple converter that transforms decimal numbers into hexadecimal.</p>
              <div className="Small-project-actions">
                <Link className='Link' to="/hexa">
                  <button className='Action-button'>
                    <Icon path={mdiOpenInNew} size={1} color="lightgray"/>
                  </button>
                </Link>
                <Link className='Link' to="https://github.com/lOlkOmOx/HexaConverter" target="_blank">
                  <button className='Action-button'>
                    <Icon path={mdiXml} size={1} color="lightgray"/>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="Experience">
          <h1>My experience</h1>
          <div className="slider">
            <div className="slide-track">
                {languages.map((lang) => (
                  <div className='slide'>
                    <LanguageCards lang={lang}/>
                  </div>
                ))}
                <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", margin:"15px 35px"}}>
                    <img src={mongoDB} height={180} alt="mongoDb"/>
                    <p>MongoDB</p>
                  </div>
                  <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", margin:"15px 35px"}}>
                    <img src={mysql} height={180} alt="mysql"/>
                    <p>MySQL</p>
                  </div>
                  <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", margin:"15px 35px"}}>
                    <img src={sqlite} height={180} alt="sqlite"/>
                    <p>SQLite</p>
                  </div>
                  <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", margin:"15px 35px"}}>
                    <img src={express} height={180} alt="sqlite" style={{filter: "invert(80%)"}}/>
                    <p>Express</p>
                  </div>
                {languages.map((lang) => (
                  <div className='slide'>
                    <LanguageCards lang={lang}/>
                  </div>
                ))}
                <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", margin:"15px 35px"}}>
                    <img src={mongoDB} height={180} alt="mongoDb"/>
                    <p>MongoDB</p>
                  </div>
                  <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", margin:"15px 35px"}}>
                    <img src={mysql} height={180} alt="mysql"/>
                    <p>MySQL</p>
                  </div>
                  <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", margin:"15px 35px"}}>
                    <img src={sqlite} height={180} alt="sqlite"/>
                    <p>SQLite</p>
                  </div>
                  <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", margin:"15px 35px"}}>
                    <img src={express} height={180} alt="sqlite" style={{filter: "invert(80%)"}}/>
                    <p>Express</p>
                  </div>
                {languages.map((lang) => (
                  <div className='slide'>
                    <LanguageCards lang={lang}/>
                  </div>
                ))}
                <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", margin:"15px 35px"}}>
                    <img src={mongoDB} height={180} alt="mongoDb"/>
                    <p>MongoDB</p>
                  </div>
                  <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", margin:"15px 35px"}}>
                    <img src={mysql} height={180} alt="mysql"/>
                    <p>MySQL</p>
                  </div>
                  <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", margin:"15px 35px"}}>
                    <img src={sqlite} height={180} alt="sqlite"/>
                    <p>SQLite</p>
                  </div>
                  <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", margin:"15px 35px"}}>
                    <img src={express} height={180} alt="sqlite" style={{filter: "invert(80%)"}}/>
                    <p>Express</p>
                  </div>

            </div>
          </div>
          <div className="Experience-Info-Container">
            <div className="Experience-card">
              <h2 className='Turquise'>  &gt; Backend</h2>
              <ul className="No-points">
                <li><Icon path={mdiNodejs} size={1} color="#339933"/> NodeJS</li>
                <li><img  className="Express" src={express} height={24} alt="Express"/> Express</li>
                <li><Icon path={mdiNpm} size={1} color="#cc3534"/> npm</li>
                <li><Icon path={mdiLanguageJavascript} size={1} color="#f7df1e"/> JavaScript</li>
              </ul>
            </div>
            <div className="Experience-card Bigger-card">
              <h2 className='Turquise'> &gt; Frontend</h2>
              <ul className="No-points">
                <li><Icon path={mdiLanguageHtml5} size={1} color="#e34c26"/> HTML</li>
                <li><Icon path={mdiLanguageCss3} size={1} color="#264de4"/> CSS</li>
                <li><Icon path={mdiReact} size={1} color="#61dbfb"/> React</li>
                <li><Icon path={mdiBootstrap} size={1} color="#6c19ff"/> Bootstrap</li>
                <li><Icon path={mdiLanguageJavascript} size={1} color="#f7df1e"/> JavaScript</li>
              </ul>
            </div>
            <div className="Experience-card">
              <h2 className='Turquise'>&gt; DB & more</h2>
              <ul className="No-points">
                <li><Icon path={mdiGit} size={1} color="#fff"/> Git</li>
                <li><img src={mongoDB} height={24} style={{padding: "0 6.5px"}} alt="mongoDb"/> MongoDB</li>
                <li><img src={mysql} height={24} alt="mySQL"/> MySQL</li>
                <li><img src={sqlite} height={24} alt="sqlite"/> SQLite</li>
              </ul>
            </div>
          </div>         
        </div>
        <div className="About-me">
          <h1>About me</h1>
          <div className='About-me-card'>
            <h3 className='Turquise'> &gt; From coffee to code</h3>
            <p className="Justify-text">
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
            </p>
          </div>
        </div>
    </div>
    <MouseShadow />
    </div>
  )
}

export default MainPage;