const dataMockup = [
    {
        id: 1,
        name: "Obst",
        ownerId: "100",
        usersIds: ["101", "102"],
        archived: false,
        items: [
            {id: 1000, name: "1 Apfel", required: true},
            {id: 1001, name: "1 Banane", required: false},
            {id: 1002, name: "1 Birne", required: true},
            {id: 1003, name: "5 Mandarinen", required: true},
            {id: 1879, name: "1 Orangen", required: true},
            {id: 1877, name: "1 Ananas", required: false},
            {id: 1876, name: "Erdbeeren", required: false},
            {id: 1875, name: "Blaubeeren", required: true},
            {id: 1874, name: "1 Granatapfel", required: true},
        ]
    },{
        id: 2,
        name: "Gemüse",
        ownerId: "100",
        usersIds: ["101", "102"],
        archived: true,
        items: [
            {id: 1004, name: "1 Gurke", required: true},
            {id: 1005, name: "1 Karotte", required: true},
            {id: 1006, name: "1 Salat", required: true},
            {id: 1007, name: "2 Brokkoli", required: true},
            {id: 1057, name: "1 Blumenkohl", required: true},
            {id: 1047, name: "3 Kohlrabi", required: true},
            {id: 1037, name: "2 Lauch", required: true},
            {id: 1027, name: "Radieschen", required: true},
        ]
    },{
        id: 3,
        name: "Milchprodukte",
        ownerId: "101",
        usersIds: ["102", "100"],
        archived: false,
        items: [
            {id: 1008, name: "Joghurt", required: true},
            {id: 1009, name: "Butter", required: true},
            {id: 1010, name: "Sahne", required: true},
            {id: 1011, name: "Quark", required: false},
            {id: 1211, name: "Trinkjoghurt", required: false},
            {id: 1311, name: "Eidam", required: true},
            {id: 1411, name: "Gouda", required: true},
            {id: 1511, name: "Hefe", required: true},
            {id: 1611, name: "Schmelzkäse", required: false},
            {id: 1711, name: "Kefir", required: false}
        ]
    },{
        id: 4,
        name: "Backen",
        ownerId: "101",
        usersIds: ["102", "103"],
        archived: false,
        items: [
            {id: 1012, name: "Backpulver", required: false},
            {id: 1013, name: "Mehl", required: false},
            {id: 1014, name: "Zucker", required: false},
            {id: 3015, name: "Marzipan", required: true},
            {id: 4015, name: "Kakao", required: true},
            {id: 5015, name: "Eier", required: true},
            {id: 6015, name: "Zitrone", required: true}
        ]
    },{
        id: 5,
        name: "Elektro",
        ownerId: "100",
        usersIds: ["101", "102", "103"],
        archived: false,
        items: [
            {id: 1016, name: "Radio", required: true},
            {id: 1017, name: "HDMI kabel", required: true},
            {id: 1018, name: "Kopfhörer", required: true},
            {id: 1019, name: "Kühlschrank", required: true},
            {id: 1519, name: "Waschmachine", required: true},
            {id: 1619, name: "Mikrwelle", required: true},
            {id: 1719, name: "USB-Stick", required: false}
        ]
    },{
        id: 6,
        name: "Für die Tante",
        ownerId: "101",
        usersIds: ["102", "103", "100"],
        archived: false,
        items: [
            {id: 1020, name: "Mehl", required: true},
            {id: 1021, name: "Kakao", required: true},
            {id: 1022, name: "Salz", required: true},
            {id: 1023, name: "Schokolade", required: true},
            {id: 1025, name: "Orchidee", required: false},
            {id: 1026, name: "Rose", required: false},
        ]
    },{
        id: 7,
        name: "Grillabend",
        ownerId: "102",
        usersIds: ["102", "101", "100"],
        archived: false,
        items: [
            {id: 1027, name: "Bratwurst", required: true},
            {id: 1028, name: "Senf", required: true},
            {id: 1029, name: "Brot", required: true},
            {id: 1030, name: "Ketchup", required: true},
            {id: 1031, name: "Breze", required: false},
            {id: 1032, name: "Briketten", required: false},
            {id: 1033, name: "Mais", required: false},
        ]
    },{
        id: 8,
        name: "Urlaub",
        ownerId: "103",
        usersIds: ["102", "101", "100"],
        archived: false,
        items: [
            {id: 1033, name: "Badeshorts", required: true},
            {id: 1034, name: "Flip-flops", required: true},
            {id: 1035, name: "Liegestuhl", required: true},
            {id: 1036, name: "Sonnencreme", required: true},
            {id: 1037, name: "Sonnenschirm", required: false}
        ]
    }
]


export default dataMockup